<template>
    <div class="container py-3">
        <h1>Dashboard</h1>

    </div>
</template>
<script>
    export default {
        name: 'AdminDashboard'
    }
</script>